@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

html {
  font-family: "Inter", sans-serif;
}

body {
  font-size: 0.875rem;
  /* background: #f6f9ff; */
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.recharts-wrapper {
  font-size: 0.8rem;
  /* font-family: Monaco, Consolas, monospace; */
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.App {
  color: #000;
  background: linear-gradient(180deg, #0b083c 0%, #1c186c 100%);
  line-height: 1.15;
  padding: 1rem;
  padding-top: 0;
}

.App.dark {
  background: black;
  color: #fff;
  line-height: 1.15;
  box-sizing: border-box;
  min-height: 100vh;
}

h3,
h2 {
  color: #444;
  margin: 1rem 0 1.5rem;
}

.App.dark h3,
.App.dark h2 {
  color: #EBECF2;
  letter-spacing: 0px;
}

h3 {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 1.2rem;
}

h2 {
  font-size: 1.6rem;
  font-weight: normal;
}

label {
  cursor: pointer;
}

input + label,
label + input {
  margin-left: 0.5rem;
}

.chart-subtitle,
.stats {
  margin: 1.5rem 0 1rem;
}

.chart-description {
  margin: 1rem 0 0;
  max-width: 600px;
  /* color: #ffffff; */
}

.App.dark .chart-description {
  color: #696C80;
}

.chart-description p:first-child {
  margin-top: 0;
}

.chart-description p:last-child {
  margin-bottom: 0;
}

.chart-description ul {
  margin: 0;
  padding-left: 20px;
}

.chart-description li {
  margin-top: 5px;
}

.chart-grid {
  color: #16182e;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.chart-grid-subtitle {
  grid-column-start: 1;
  grid-column-end: 9;
  margin-bottom: 0;
  margin-top: 2rem;
}

.total-stat-label {
  font-size: 0.875rem;
  color: #696C80;
  letter-spacing: 0px;
  line-height: 1.875rem;
}

.total-stat-value {
  font-size: 1.25rem;
  color: #EBECF2;
  line-height: 1.875rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.total-stat-delta {
  font-size: 0.875rem;
  letter-spacing: 0px;
  line-height: 1.875rem;
  display: block;
}

.total-stat-delta.plus {
  color: #63B48E;
}
.App.light .total-stat-delta.plus {
  color: rgb(34 199 97);
}
.total-stat-delta.minus {
  color: #727171;
}

.App.dark .chart-cell {
  /* background: rgba(118, 118, 128, 0.24); */
  border: none;
  color: white;
}

.App.dark .chart-cell.stats {
  /* border: 0.5px solid rgba(255, 255, 255, 0.15); */
}

.chart-cell {
  margin: 0;
  border-radius: 12px;
  border: 1px solid #ffffff14;
  background: #1B1E29 0% 0% no-repeat padding-box;
  position: relative;
  padding: 1rem;
  grid-column-start: span 5;
}

.chart-cell.stats {
  min-height: 60px;
  grid-column-start: span 2;
  text-align: center;
}

.chart-cell.experiment {
  border-color: #fbb;
}
.chart-cell.experiment:after {
  color: #f55;
  top: 1rem;
  right: 1rem;
  position: absolute;
  content: "(experimental)";
}

.chart-cell h3 {
  margin-top: 0;
  letter-spacing: 0px;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.chart-cell .csv-link {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.3rem;
  /* opacity: 0.5; */
  display: inline-flex;
  vertical-align: middle;
}
.chart-cell:hover .csv-link {
  /* opacity: 0.5; */
}
.chart-cell .csv-link:hover {
  opacity: 0.5;
}

.form input[type="date"],
.form input[type="text"] {
  border: 1px solid #aaa;
  border-radius: 3px;
  appearance: none !important;
  box-sizing: border-box;
  padding: 3px;
}

.form button {
  border: none;
  appearance: none !important;
  color: #000;
  border: 1px solid #ffffff0f;
  box-sizing: border-box;
  background: #8f8db1;
  cursor: pointer;
  margin-left: 10px;
  height: 28px;
  border-radius: 3px;
  padding: 3px 7px;
}
.form button:hover {
  opacity: 0.9;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1.5s infinite;
  opacity: 0.3;
}
.chart-cell .loader {
  position: absolute;
  margin-left: -1.5em;
  margin-top: -1.5em;
  font-size: 0.7rem;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.warning,
.warning a {
  color: #e46b00;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -1rem 0;
  height: 55px;
  padding-left: 1rem;
  padding-right: 1rem;
  /* border-bottom: 1px solid #ffffff; */
  background-color: white;
}

.App.dark .nav {
  background: rgba(11, 8, 60, 0.1);
  padding: 22px 0;
  padding-left: 16px;
  /* border-bottom: 1px solid #ffffff29; */
  position: relative;
  z-index: 10;
}

.nav-logo {
  vertical-align: middle;
  padding-left: 8px;
  padding-right: 36px;
  display: inline-flex;
}

.nav-logo img {
  /* width: 152px; */
  height: 28px;
  background: transparent ;
}

a,
a:active,
a:visited {
  color: #16182e;
}

.nav-link {
  /* letter-spacing: 0.47px; */
  color: #ffffff;
  /* color: #000; */
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 1.125rem;
  letter-spacing: 0px;
}

.nav-link:active,
.nav-link:visited {
  color: #ffffff;
}

.nav-link.active {
  color: #444;
}

.App.dark a,
.App.dark a:active,
.App.dark a:visited {
  color: #696C80;
}

/* .App.dark .nav-link {
  color: #ffffff;
} */

.App.dark .nav-link:active,
.App.dark .nav-link:visited {
  color: #696C80;
}

.App.dark .nav-link.active {
  border-radius: 8px;
  background: #252833 0% 0% no-repeat padding-box;
  color: white;
}

.App.dark .nav-link:hover {
  /* color: white; */
}

.nav-link:hover {
  color: #444;
}

.nav-right {
  display: flex;
  align-items: center;
}

.modeselect {
  width: 30px;
  height: 26px;
  background: #16182e 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0f;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App.dark .modeselect {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.modeselect svg {
  fill: #ffffff;
}

.App.dark .modeselect svg {
  fill: #ffffff0f;
}

.page-title {
  font-size: 1.8125rem;
  line-height: 2.3125rem;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-axis-tick-value {
  fill: #fff;
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-grid-horizontal line,
.App.dark .recharts-cartesian-grid-vertical line {
  stroke: #ffffff0f;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  padding: 5px 12px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li {
  padding: 0px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li + li {
  margin-top: 1px !important;
}

.App.dark .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: #1B1E29 !important;
  box-shadow: 0px 3px 6px #0000005c;
  border: 1px solid #484b6e !important;
  border-radius: 4px;
}

.App-header-drawer {
  background: #1B1E29 0% 0% no-repeat padding-box;
  box-shadow: 8px 3px 6px #00000029;
  opacity: 1;
  backdrop-filter: blur(27px);
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 304px;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #ffffff;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: rgba(118, 118, 128, 0.24);
  color: white;
}

.App-header-drawer .App-header-menu-icon {
  color: white;
  margin: unset;
  font-size: 2rem;
}

.App-header-drawer .App-header-link-main {
  display: flex;
  align-items: center;
}

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding-left: 1rem;
  padding-right: 1rem;
}

.App-header-menu-icon {
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App.dark .App-header-menu-icon {
  color: white;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

@media all and (max-width: 1000px) {
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .chart-cell {
    grid-column-start: span 2;
    grid-column-end: auto;
  }
  .chart-cell.stats {
    grid-column-start: span 1;
  }
}

.content {
  margin-top: 1rem;
  max-width: 1136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.App-header-menu-icon-block {
  display: none;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media all and (max-width: 600px) {
  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .chart-cell {
    grid-column-start: unset !important;
    grid-column-end: unset !important;
  }

  .App-header-menu-icon-block {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
  }

  .nav-left {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    display: flex;
  }

  .nav-left .nav-link {
    display: none;
  }
}

.page-description {
  font-size: 16px;
  color: #696C80;
  letter-spacing: 0px;
}
